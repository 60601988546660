// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'
import {
  AdStarBlock,
  GeneralSettings,
  NationalWeekendEscape
} from '../../types'

export interface ISchoolReviewsSearchQueryData {
  wpgraphql: {
    adStarCodes: AdStarBlock[]
    generalSettings: GeneralSettings
  }
}

export const SchoolReviewsResultQuery = gql`
  query ($category: String) {
    nationalSchoolReviews(category: $category) {
      id
      title
      uri
      county
      homepagePreviewImage
      description
      facilities
    }
  }
`

export const SchoolReviewsTextQuery = gql`
  query ($search: String) {
    schoolReviewsSearch(search: $search) {
      id
      title
      uri
      county
      homepagePreviewImage
      description
      facilities
    }
  }
`

export interface ISchoolReviewsResultQueryData {
  nationalSchoolReviews: NationalWeekendEscape[]
}

export interface ISchoolReviewsTextQueryData {
  schoolReviewsSearch: NationalWeekendEscape[]
}
