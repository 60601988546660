import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SubLeader from '../../../components/SubLeader/SubLeader'
import BlockResults from '../../../components/BlockResults/BlocksResults'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import PageMeta from '../../../components/PageMeta/PageMeta'
import { NationalWeekendEscape } from '../../../types'
import {
  SchoolReviewsResultQuery,
  SchoolReviewsTextQuery,
  type ISchoolReviewsSearchQueryData,
  type ISchoolReviewsResultQueryData,
  type ISchoolReviewsTextQueryData
} from '../../../graphql/queries/SchoolReviewsSearchQuery'
import SchoolReviewsLauncher from '../../../components/organisms/schoolReviewsLauncher/SchoolReviewsLauncher'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      lBBCategory(id: "experiences", idType: SLUG) {
        description
        name
      }
    }
  }
`

interface SchoolReviewsSearchProps {
  data: ISchoolReviewsSearchQueryData
  location: Location
}

const SchoolReviewsSearch = ({
  data: {
    wpgraphql: { adStarCodes, generalSettings }
  },
  location
}: SchoolReviewsSearchProps) => {
  const { siteId, title } = generalSettings
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const params = new URLSearchParams(location.search)
  const county = params.get('county')
  const [searchTerm, setSearchTerm] = useState<string | null>(
    params.get('search') ?? null
  )
  const [posts, setPosts] = useState<
    ISchoolReviewsResultQueryData | ISchoolReviewsTextQueryData
  >()
  const [postsLoading, setPostsLoading] = useState<boolean>(false)
  const [escapePosts, setEscapePosts] = useState<NationalWeekendEscape[]>()

  const searchQuery = useQuery<ISchoolReviewsTextQueryData>(
    SchoolReviewsTextQuery,
    {
      variables: { search: searchTerm }
    }
  )

  const catQuery = useQuery<ISchoolReviewsResultQueryData>(
    SchoolReviewsResultQuery
  )

  useEffect(() => {
    setPosts(searchQuery.data)
    setPostsLoading(searchQuery.loading)
  }, [searchQuery])

  useEffect(() => {
    if (!searchTerm) {
      setPosts(catQuery.data)
      setPostsLoading(catQuery.loading)
    }
  }, [catQuery])

  useEffect(() => {
    if (posts && 'nationalSchoolReviews' in posts) {
      setEscapePosts(posts.nationalSchoolReviews)
    } else if (posts && 'schoolReviewsSearch' in posts) {
      setEscapePosts(posts.schoolReviewsSearch)
    }
  }, [posts])

  // const filterSearch = (results: NationalWeekendEscape[]) => {
  //   if (searchTerm)
  //     return results.filter(escape =>
  //       escape.title
  //         ?.replace(/'/g, '')
  //         .replace(/&#8217;/g, '')
  //         .replace(/\./g, '')
  //         .toLowerCase()
  //         .includes(
  //           searchTerm
  //             .replace(/'/g, '')
  //             .replace(/&#8217;/g, '')
  //             .replace(/\./g, '')
  //             .toLowerCase()
  //         )
  //     )

  //   if (county && !postsLoading)
  //     return results.filter(escape =>
  //       escape.county?.toLowerCase().includes(county.toLowerCase())
  //     )

  //   return results
  // }

  const handleSearchChange = (sTerm: string) => {
    if (sTerm !== searchTerm) {
      if (sTerm.length < 1) {
        navigate('/school-reviews/search/')
        setSearchTerm('')
      } else {
        setSearchTerm(sTerm)
        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        const url = new URL(window.location.href)
        url.searchParams.set('search', sTerm) // Set your parameter and value here
        window.history.pushState({}, '', url)
      }
    }
  }

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: `${'School Reviews'} | Muddy Stilettos' Best Schools Guide`,
          description:
            'No boring fusty reviews here! Our ‘insider’ guides give a genuine insight into each school we visit, with informal, super-useful advice from our editors.',
          image:
            'https://muddystilettos.co.uk/images/school-reviews/Leader.jpg',
          url: `https://muddystilettos.co.uk/school-reviews/search/${
            searchTerm && `?search=${searchTerm}`
          }`
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner ads={adStarCodes} pageType={`weekendescapes`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <SubLeader>
        <SchoolReviewsLauncher
          currentValue={searchTerm ?? undefined}
          handleSearchChange={handleSearchChange}
        />
      </SubLeader>
      <BlockResults
        category={`Start your search`}
        loading={postsLoading}
        posts={escapePosts ?? []}
        type={`school-reviews`}
        {...(county && { county: county })}
      />
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default SchoolReviewsSearch
